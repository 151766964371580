import * as Sentry from "@sentry/nuxt";

Sentry.init({
  //dsn: "https://52a82ef314385e4e57eb61e5d3ae023f@o4508018126290944.ingest.de.sentry.io/4508018147852368",
  dsn: "https://a517b7e499d10a6f5954805a5327f0a7@o4508018126290944.ingest.de.sentry.io/4508018151718992",

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});
